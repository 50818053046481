<template>
    <div>
        <div class="card no-border px-5 pt-5 pb-4 radius-16 text-center">
            <!-- Step 1 -->
            <div v-if="currentStep === 1" class="step1">
                <div class="card-title">
                    <p class="mb-2 dark-text fw-bold h5">من فضلك</p>
                    <p class="mb-0 orange-text fw-bold h5">ادخل ابعاد الارض</p>
                </div>
                <div class="card-body mt-4">
                    <div class="row justify-content-center">
                        <div class="col-md-5 col-sm-12 col-lg-5 col-xs-12">
                            <!-- Length Input -->
                            <div class="d-flex dimensions-container mb-3">
                                <label class="me-3 mt-2">الطول</label>
                                <div class="input-group">
                                    <button class="btn" type="button" @click="decrement('length')">
                                        <i class="fas fa-minus"></i>
                                    </button>
                                    <input type="text" v-model="formattedLength" @input="updateLength"
                                        class="form-control input dark-text" aria-label="طول الأرض" />
                                    <span class="input-group-text">م</span>
                                    <button class="btn" type="button" @click="increment('length')">
                                        <i class="fas fa-plus"></i>
                                    </button>
                                </div>
                            </div>
                            <!-- Width Input -->
                            <div class="d-flex dimensions-container">
                                <label class="me-3 mt-2">العرض</label>
                                <div class="input-group">
                                    <button class="btn" type="button" @click="decrement('width')">
                                        <i class="fas fa-minus"></i>
                                    </button>
                                    <input type="text" v-model="formattedWidth" @input="updateWidth"
                                        class="form-control input dark-text" aria-label="عرض الأرض" />
                                    <span class="input-group-text">م</span>
                                    <button class="btn" type="button" @click="increment('width')">
                                        <i class="fas fa-plus"></i>
                                    </button>
                                </div>
                            </div>
                            <!-- Area Calculation -->
                            <p class="mt-3">
                                مساحة الأرض =
                                <span class="blue-text">{{ area.toFixed(2) }} متر مربع</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="card-footer bg-transparent no-border mt-5">
                    <div class="row">
                        <div class="col-md-9 col-lg-9 col-sm-12 col-xs-12">
                            <div class="progress mt-3">
                                <div class="progress-bar" :style="{ width: '10%' }" role="progressbar"
                                    aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <p class="text-center fw-bold mt-3 mb-0"><span class="blue-text">1/</span>10</p>
                        </div>
                        <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 text-end">
                            <button type="button" class="btn btn-primary radius-16 py-2 px-5" @click="nextStep">
                                التالى
                                <i class="fas fa-arrow-left ps-2"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Step 2 -->
            <div v-if="currentStep === 2" class="step2">
                <div class="card-title">
                    <p class="mb-2 dark-text fw-bold h5">ما هي أطوال الجهات التي</p>
                    <p class="mb-0 orange-text fw-bold h5">تطل علي الشارع في ارضك؟</p>
                </div>

                <div class="card-body mt-5">
                    <div class="btn-group mt-2" role="group" aria-label="Basic checkbox group">
                        <button type="button" class="btn btn-outline-secondary with-height"
                            :class="{ 'active': activeButtons.includes('1') }" @click="toggleButton('1')">
                            {{ formattedLength }}
                        </button>
                        <button type="button" class="btn btn-outline-secondary with-height"
                            :class="{ 'active': activeButtons.includes('2') }" @click="toggleButton('2')">
                            {{ formattedWidth }}
                        </button>
                        <button type="button" class="btn btn-outline-secondary with-height"
                            :class="{ 'active': activeButtons.includes('3') }" @click="toggleButton('3')">
                            {{ formattedLength }}
                        </button>
                        <button type="button" class="btn btn-outline-secondary with-height"
                            :class="{ 'active': activeButtons.includes('4') }" @click="toggleButton('4')">
                            {{ formattedWidth }}
                        </button>

                    </div>

                    <div class="content-wrapper mt-5">
                        <p class="dark-text fw-bold mb-3">
                            <span class="label h8">الأرض تطل على</span>
                            <span class="value h8">{{ frontageDescription }}</span>
                        </p>

                        <div v-if="activeButtons.length === 0" class="step-item">
                            <p class="mt-1 mb-0">لم يتم اختيار أي جهة</p>
                        </div>
                        <div v-else class="step-item" v-for="buttonId in activeButtons" :key="buttonId">
                            <p class="mt-1 mb-0">
                                طول الواجهة {{ buttonId }} =
                                <span class="blue-text">{{ frontageValues[buttonId].toFixed(2) }} متر</span>
                            </p>
                        </div>
                    </div>
                </div>

                <div class="card-footer bg-transparent no-border mt-5">
                    <div class="row">
                        <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 text-start">
                            <button v-if="currentStep > 1" type="button" class="btn btn-gray radius-16 py-2 px-5"
                                @click="prevStep">
                                <i class="fas fa-arrow-right pe-2"></i>
                                السابق
                            </button>
                        </div>
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                            <div class="progress mt-3">
                                <div class="progress-bar" :style="{ width: '30%' }" role="progressbar"
                                    aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <p class="text-center fw-bold mt-3 mb-0"><span class="blue-text">2/</span>10</p>
                            <!-- Alert Dialog -->
                            <div v-if="showAlertDialog" class="alert alert-danger d-flex align-items-center"
                                role="alert">
                                <button class="closeAlert" type="button" @click="closeAlert">
                                    <i class="fas fa-times"></i>
                                </button>
                                <div class="size-8">
                                    <i class="fas fa-exclamation-triangle pe-2"></i>
                                    من فضلك اختر جهتين على الأقل قبل الانتقال إلى الخطوة التالية.
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 text-end">
                            <button type="button" class="btn btn-primary radius-16 py-2 px-5" @click="nextStep">
                                التالى
                                <i class="fas fa-arrow-left ps-2"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Step 3 -->
            <div v-if="currentStep === 3" class="step3">
                <div class="card-title">
                    <p class="mb-2 dark-text fw-bold h5">من فضلك</p>
                    <p class="mb-0 orange-text fw-bold h5">إختر نوع الفيلا التى تريد بنائها</p>
                </div>
                <div class="card-body mt-5">
                    <div class="btn-group villa-type mt-2" role="group" aria-label="Basic checkbox group">
                        <button type="button" class="btn btn-outline-dark"
                            :class="{ 'active': selectedVilla === 'one' }" @click="selectVillaType('one')">
                            <div class="img">
                                <img src="/assets/web/images/calculation/onefloorvilla.svg" alt="" />
                            </div>
                            <p class="blue-text">فيلا دور واحد</p>
                        </button>
                        <button type="button" class="btn btn-outline-dark"
                            :class="{ 'active': selectedVilla === 'two' }" @click="selectVillaType('two')">
                            <div class="img">
                                <img src="/assets/web/images/calculation/twofloorvilla.svg" alt="" />
                            </div>
                            <p class="blue-text">فيلا دورين </p>
                        </button>
                    </div>
                </div>
                <div class="card-footer bg-transparent no-border mt-5">
                    <div class="row">
                        <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 text-start">
                            <button v-if="currentStep > 1" type="button" class="btn btn-gray radius-16 py-2 px-5"
                                @click="prevStep">
                                <i class="fas fa-arrow-right pe-2"></i>
                                السابق
                            </button>
                        </div>
                        <div class="col-md-9 col-lg-9 col-sm-12 col-xs-12">
                            <div class="progress mt-3">
                                <div class="progress-bar" :style="{ width: '40%' }" role="progressbar"
                                    aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <p class="text-center fw-bold mt-3 mb-0"><span class="blue-text">3/</span>10</p>
                        </div>
                    </div>
                </div>
            </div>
            <!--step4-->
            <div v-if="currentStep === 4" class="step4">
                <div class="card-title">
                    <p class="mb-2 dark-text fw-bold h5">من فضلك</p>
                    <p class="mb-0 orange-text fw-bold h5">إدخل مساحة الدور الأرضى</p>
                </div>
                <div class="card-body mt-5">
                    <div class="row justify-content-center">
                        <div class="col-md-5 col-sm-12 col-lg-5 col-xs-12">
                            <!-- Ground floor area -->
                            <div class="d-flex dimensions-container mb-3">
                                <div class="input-group">
                                    <button class="btn" type="button" @click="decrement('groundArea')">
                                        <i class="fas fa-minus"></i>
                                    </button>
                                    <input type="text" v-model="formattedGroundArea" @input="updateGroundArea"
                                        class="form-control input dark-text" aria-label="طول الأرض" />
                                    <span class="input-group-text">م</span>
                                    <button class="btn" type="button" @click="increment('groundArea')">
                                        <i class="fas fa-plus"></i>
                                    </button>

                                </div>
                            </div>
                            <p class="mb-0">يجب الا تزيد مساحة الدور الأرضي </p>
                            <p class="mb-0">عن 70% من مساحة الأرض</p>
                            <div v-if="showAlertDialog2" class="mt-2 alert alert-danger d-flex align-items-center"
                                role="alert">
                                <div class="size-8">
                                    <i class="fas fa-exclamation-triangle pe-2"></i>
                                    يجب ألا تزيد مساحة الدور الأرضى عن 70% من مساحة الأرض
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer bg-transparent no-border mt-5">
                    <div class="row">
                        <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 text-start">
                            <button v-if="currentStep > 1" type="button" class="btn btn-gray radius-16 py-2 px-5"
                                @click="prevStep">
                                <i class="fas fa-arrow-right pe-2"></i>
                                السابق
                            </button>
                        </div>
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                            <div class="progress mt-3">
                                <div class="progress-bar" :style="{ width: '50%' }" role="progressbar"
                                    aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <p class="text-center fw-bold mt-3 mb-0"><span class="blue-text">4/</span>10</p>
                        </div>
                        <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 text-end">
                            <button type="button" class="btn btn-primary radius-16 py-2 px-5" @click="goToNextStep()">
                                التالى
                                <i class="fas fa-arrow-left ps-2"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!--step one turn for villa two-->
            <div v-if="currentStep === 'one-turn' && selectedVilla === 'two'">
                <div class="card-title">
                    <p class="mb-2 dark-text fw-bold h5">من فضلك</p>
                    <p class="mb-0 orange-text fw-bold h5">إدخل مساحة الدور الأول</p>
                </div>
                <div class="card-body mt-5">
                    <div class="row justify-content-center">
                        <div class="col-md-5 col-sm-12 col-lg-5 col-xs-12">
                            <!-- Ground floor area -->
                            <div class="d-flex dimensions-container mb-3">
                                <div class="input-group">
                                    <button class="btn" type="button" @click="decrement('turnArea')">
                                        <i class="fas fa-minus"></i>
                                    </button>
                                    <input type="text" v-model="formattedTurnArea" @input="updateTurnArea"
                                        class="form-control input dark-text" aria-label="" />
                                    <span class="input-group-text">م</span>
                                    <button class="btn" type="button" @click="increment('turnArea')">
                                        <i class="fas fa-plus"></i>
                                    </button>

                                </div>
                            </div>
                            <p class="mb-0">يجب الا تزيد مساحة الدور الأول </p>
                            <p class="mb-0">عن 65% من مساحة الأرض</p>
                            <div v-if="showAlertDialog12" class="mt-2 alert alert-danger d-flex align-items-center"
                                role="alert">
                                <div class="size-8">
                                    <i class="fas fa-exclamation-triangle pe-2"></i>
                                    يجب ألا تزيد مساحة الدور الأول عن 65% من مساحة الأرض
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer bg-transparent no-border mt-5">
                    <div class="row">
                        <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 text-start">
                            <button type="button" class="btn btn-gray radius-16 py-2 px-5" @click="prevStep">
                                <i class="fas fa-arrow-right pe-2"></i>
                                السابق
                            </button>
                        </div>
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                            <div class="progress mt-3">
                                <div class="progress-bar" :style="{ width: '50%' }" role="progressbar"
                                    aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <p class="text-center fw-bold mt-3 mb-0"><span class="blue-text">4/</span>10</p>
                        </div>
                        <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 text-end">
                            <button type="button" class="btn btn-primary radius-16 py-2 px-5" @click="goToStep5()">
                                التالى
                                <i class="fas fa-arrow-left ps-2"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!--step5-->
            <div v-if="currentStep === 5" class="step5">
                <div class="card-title">
                    <p class="mb-2 dark-text fw-bold h5">هل تخطط</p>
                    <p class="mb-0 orange-text fw-bold h5">لبناء ملحق علوى ؟</p>
                </div>
                <div class="card-body mt-5">
                    <div class="btn-group villa-type mt-2" role="group" aria-label="Basic checkbox group">
                        <button type="button" class="btn btn-outline-dark" @click="upperArea(1)">
                            <div class="check-icon mb-3">
                                <i class="fas fa-check h1 dark-text"></i>
                            </div>
                            <p class="text-success fw-bold dark-text">نعم</p>
                        </button>
                        <button type="button" class="btn btn-outline-dark" @click="upperArea(0)">
                            <div class="check-icon mb-3">
                                <i class="fas fa-times h1 dark-text"></i>
                            </div>
                            <p class="text-danger fw-bold"> لا </p>
                        </button>
                    </div>
                </div>
                <div class="card-footer bg-transparent no-border mt-5">
                    <div class="row">
                        <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 text-start">
                            <button v-if="currentStep > 1" type="button" class="btn btn-gray radius-16 py-2 px-5"
                                @click="prevStep">
                                <i class="fas fa-arrow-right pe-2"></i>
                                السابق
                            </button>
                        </div>
                        <div class="col-md-9 col-lg-9 col-sm-12 col-xs-12">
                            <div class="progress mt-3">
                                <div class="progress-bar" :style="{ width: '60%' }" role="progressbar"
                                    aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <p class="text-center fw-bold mt-3 mb-0"><span class="blue-text">5/</span>10</p>
                        </div>
                    </div>
                </div>
            </div>
            <!--step6-->
            <div v-if="currentStep === 6" class="step6">
                <div class="card-title">
                    <p class="mb-2 dark-text fw-bold h5"> من فضلك</p>
                    <p class="mb-0 orange-text fw-bold h5"> ادخل مساحة الملحق العلوى </p>
                </div>
                <div class="card-body mt-5">
                    <div class="row justify-content-center">
                        <div class="col-md-5 col-sm-12 col-lg-5 col-xs-12">
                            <!-- Upper Area -->
                            <div class="d-flex dimensions-container mb-3">
                                <div class="input-group">
                                    <button class="btn" type="button" @click="decrement('upperAreaFloor')">
                                        <i class="fas fa-minus"></i>
                                    </button>
                                    <input type="text" v-model="formattedUpperAreaFloor" @input="updateUpperAreaFloor"
                                        class="form-control input dark-text" aria-label="طول الأرض" />
                                    <span class="input-group-text">م</span>
                                    <button class="btn" type="button" @click="increment('upperAreaFloor')">
                                        <i class="fas fa-plus"></i>
                                    </button>
                                </div>
                            </div>
                            <p class="mb-0">يجب الا تزيد مساحة الدور العلوى </p>
                            <p class="mb-0">عن 50% من مساحة الدور الأرضى</p>
                            <div v-if="showAlertDialog3" class="mt-2 alert alert-danger d-flex align-items-center"
                                role="alert">
                                <div class="size-8">
                                    <i class="fas fa-exclamation-triangle pe-2"></i>
                                    يجب ألا تزيد مساحة الدور العلوى عن 50% مساحة الدور الأرضى
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer bg-transparent no-border mt-5">
                    <div class="row">
                        <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 text-start">
                            <button v-if="currentStep > 1" type="button" class="btn btn-gray radius-16 py-2 px-5"
                                @click="prevStep">
                                <i class="fas fa-arrow-right pe-2"></i>
                                السابق
                            </button>
                        </div>
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                            <div class="progress mt-3">
                                <div class="progress-bar" :style="{ width: '70%' }" role="progressbar"
                                    aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <p class="text-center fw-bold mt-3 mb-0"><span class="blue-text">6/</span>10</p>
                        </div>
                        <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 text-end">
                            <button type="button" class="btn btn-primary radius-16 py-2 px-5" @click="cellarStep">
                                التالى
                                <i class="fas fa-arrow-left ps-2"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!--step7-->
            <div v-if="currentStep === 7" class="step7">
                <div class="card-title">
                    <p class="mb-2 dark-text fw-bold h5">هل تخطط</p>
                    <p class="mb-0 orange-text fw-bold h5">لبناء قبو (بدروم) ؟</p>
                </div>
                <div class="card-body mt-5">
                    <div class="btn-group villa-type mt-2" role="group" aria-label="Basic checkbox group">
                        <button type="button" class="btn btn-outline-dark" @click="cellarArea(1)">
                            <div class="check-icon mb-3">
                                <i class="fas fa-check h1 dark-text"></i>
                            </div>
                            <p class="text-success fw-bold dark-text">نعم</p>
                        </button>
                        <button type="button" class="btn btn-outline-dark" @click="cellarArea(0)">
                            <div class="check-icon mb-3">
                                <i class="fas fa-times h1 dark-text"></i>
                            </div>
                            <p class="text-danger fw-bold"> لا </p>
                        </button>
                    </div>
                </div>
                <div class="card-footer bg-transparent no-border mt-5">
                    <div class="row">
                        <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 text-start">
                            <button v-if="currentStep > 1" type="button" class="btn btn-gray radius-16 py-2 px-5"
                                @click="prevStep">
                                <i class="fas fa-arrow-right pe-2"></i>
                                السابق
                            </button>
                        </div>
                        <div class="col-md-9 col-lg-9 col-sm-12 col-xs-12">
                            <div class="progress mt-3">
                                <div class="progress-bar" :style="{ width: '60%' }" role="progressbar"
                                    aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <p class="text-center fw-bold mt-3 mb-0"><span class="blue-text">5/</span>10</p>
                        </div>
                    </div>
                </div>
            </div>
            <!--step8 cellar area-->
            <div v-if="currentStep === 8" class="step6">
                <div class="card-title">
                    <p class="mb-2 dark-text fw-bold h5">من فضلك</p>
                    <p class="mb-0 orange-text fw-bold h5">ادخل مساحة القبو</p>
                </div>
                <div class="card-body mt-5">
                    <div class="row justify-content-center">
                        <div class="col-md-5 col-sm-12 col-lg-5 col-xs-12">
                            <!-- Cellar Area -->
                            <div class="d-flex dimensions-container mb-3">
                                <div class="input-group">
                                    <button class="btn" type="button" @click="decrement('cellar')">
                                        <i class="fas fa-minus"></i>
                                    </button>
                                    <input type="text" v-model="formattedArea" @input="updateCellar"
                                        class="form-control input dark-text" aria-label="طول الأرض" />
                                    <span class="input-group-text">م</span>
                                    <button class="btn" type="button" @click="increment('cellar')">
                                        <i class="fas fa-plus"></i>
                                    </button>
                                </div>
                            </div>
                            <p class="mb-0">يسمح بإنشاء القبو على كامل مساحة الأرض</p>
                        </div>
                    </div>
                </div>
                <div class="card-footer bg-transparent no-border mt-5">
                    <div class="row">
                        <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 text-start">
                            <button v-if="currentStep > 1" type="button" class="btn btn-gray radius-16 py-2 px-5"
                                @click="prevStep">
                                <i class="fas fa-arrow-right pe-2"></i>
                                السابق
                            </button>
                        </div>
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                            <div class="progress mt-3">
                                <div class="progress-bar" :style="{ width: '70%' }" role="progressbar"
                                    aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <p class="text-center fw-bold mt-3 mb-0"><span class="blue-text">6/</span>10</p>
                        </div>
                        <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 text-end">
                            <button type="button" class="btn btn-primary radius-16 py-2 px-5" @click="GoToStep9">
                                التالى
                                <i class="fas fa-arrow-left ps-2"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Step 9 Tank dimensions -->
            <div v-if="currentStep === 9" class="step9">
                <div class="card-title">
                    <p class="mb-2 dark-text fw-bold h5">من فضلك</p>
                    <p class="mb-0 orange-text fw-bold h5">ادخل ابعاد خزان المياة الأرضى</p>
                </div>
                <div class="card-body mt-4">
                    <div class="row justify-content-center">
                        <div class="col-md-5 col-sm-12 col-lg-5 col-xs-12">
                            <!-- Length Input -->
                            <div class="d-flex dimensions-container mb-3">
                                <label class="me-3 mt-2">الطول</label>
                                <div class="input-group">
                                    <button class="btn" type="button" @click="decrement('tankLength')">
                                        <i class="fas fa-minus"></i>
                                    </button>
                                    <input type="text" v-model="formattedTankLength" @input="updateTankLength"
                                        class="form-control input dark-text" aria-label=" الطول" />
                                    <span class="input-group-text">م</span>
                                    <button class="btn" type="button" @click="increment('tankLength')">
                                        <i class="fas fa-plus"></i>
                                    </button>
                                </div>
                            </div>
                            <!-- Width Input -->
                            <div class="d-flex dimensions-container">
                                <label class="me-3 mt-2">العرض</label>
                                <div class="input-group">
                                    <button class="btn" type="button" @click="decrement('tankWidth')">
                                        <i class="fas fa-minus"></i>
                                    </button>
                                    <input type="text" v-model="formattedTankWidth" @input="updateTankWidth"
                                        class="form-control input dark-text" aria-label="عرض الأرض" />
                                    <span class="input-group-text">م</span>
                                    <button class="btn" type="button" @click="increment('tankWidth')">
                                        <i class="fas fa-plus"></i>
                                    </button>
                                </div>
                            </div>
                            <!-- Area Calculation -->
                            <p class="mt-3 mb-0">
                                مساحة خزان المياة =
                                <span class="blue-text">{{ tankArea.toFixed(2) }} متر مكعب</span>
                            </p>
                            <p>ارتفاع الخزان الصافي (3.00) متر</p>
                            <div v-if="showAlertDialogTankLength"
                                class="mt-2 alert alert-danger d-flex align-items-center" role="alert">
                                <div class="size-8">
                                    <i class="fas fa-exclamation-triangle pe-2"></i>
                                    الحد الأقصى لطول الخزان 15
                                </div>
                            </div>
                            <div v-if="showAlertDialogTankWidth"
                                class="mt-2 alert alert-danger d-flex align-items-center" role="alert">
                                <div class="size-8">
                                    <i class="fas fa-exclamation-triangle pe-2"></i>
                                    الحد الأقصى لعرض الخزان 8
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer bg-transparent no-border mt-5">
                    <div class="row">
                        <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 text-start">
                            <button v-if="currentStep > 1" type="button" class="btn btn-gray radius-16 py-2 px-5"
                                @click="prevStep">
                                <i class="fas fa-arrow-right pe-2"></i>
                                السابق
                            </button>
                        </div>
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                            <div class="progress mt-3">
                                <div class="progress-bar" :style="{ width: '80%' }" role="progressbar"
                                    aria-valuenow="80" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <p class="text-center fw-bold mt-3 mb-0"><span class="blue-text">7/</span>10</p>
                        </div>
                        <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 text-end">
                            <button type="button" class="btn btn-primary radius-16 py-2 px-5" @click="nextStep">
                                التالى
                                <i class="fas fa-arrow-left ps-2"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Step 10 Plan dimensions -->
            <div v-if="currentStep === 10" class="step10">
                <div class="card-title">
                    <p class="mb-2 dark-text fw-bold h5">من فضلك</p>
                    <p class="mb-0 orange-text fw-bold h5">ادخل ابعاد بيارة الصرف</p>
                </div>
                <div class="card-body mt-4">
                    <div class="row justify-content-center">
                        <div class="col-md-5 col-sm-12 col-lg-5 col-xs-12">
                            <!-- Length Input -->
                            <div class="d-flex dimensions-container mb-3">
                                <label class="me-3 mt-2">الطول</label>
                                <div class="input-group">
                                    <button class="btn" type="button" @click="decrement('planLength')">
                                        <i class="fas fa-minus"></i>
                                    </button>
                                    <input type="text" v-model="formattedPlanLength" @input="updatePlanLength"
                                        class="form-control input dark-text" aria-label=" الطول" />
                                    <span class="input-group-text">م</span>
                                    <button class="btn" type="button" @click="increment('planLength')">
                                        <i class="fas fa-plus"></i>
                                    </button>
                                </div>
                            </div>
                            <!-- Width Input -->
                            <div class="d-flex dimensions-container">
                                <label class="me-3 mt-2">العرض</label>
                                <div class="input-group">
                                    <button class="btn" type="button" @click="decrement('planWidth')">
                                        <i class="fas fa-minus"></i>
                                    </button>
                                    <input type="text" v-model="formattedPlanWidth" @input="updatePlanWidth"
                                        class="form-control input dark-text" aria-label="عرض الأرض" />
                                    <span class="input-group-text">م</span>
                                    <button class="btn" type="button" @click="increment('planWidth')">
                                        <i class="fas fa-plus"></i>
                                    </button>
                                </div>
                            </div>
                            <!-- Area Calculation -->
                            <p class="mt-3 mb-0">
                                حجم بيارة الصرف =
                                <span class="blue-text">{{ planArea.toFixed(2) }} متر مكعب</span>
                            </p>
                            <p>ارتفاع البيارة الصافي (3.00) متر</p>
                            <div v-if="showAlertDialogPlanLength"
                                class="mt-2 alert alert-danger d-flex align-items-center" role="alert">
                                <div class="size-8">
                                    <i class="fas fa-exclamation-triangle pe-2"></i>
                                    الحد الأقصى لطول بيارة الصرف 15
                                </div>
                            </div>
                            <div v-if="showAlertDialogPlanWidth"
                                class="mt-2 alert alert-danger d-flex align-items-center" role="alert">
                                <div class="size-8">
                                    <i class="fas fa-exclamation-triangle pe-2"></i>
                                    الحد الأقصى لعرض بيارة الصرف 8
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer bg-transparent no-border mt-5">
                    <div class="row">
                        <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 text-start">
                            <button v-if="currentStep > 1" type="button" class="btn btn-gray radius-16 py-2 px-5"
                                @click="prevStep">
                                <i class="fas fa-arrow-right pe-2"></i>
                                السابق
                            </button>
                        </div>
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                            <div class="progress mt-3">
                                <div class="progress-bar" :style="{ width: '90%' }" role="progressbar"
                                    aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <p class="text-center fw-bold mt-3 mb-0"><span class="blue-text">8/</span>10</p>
                        </div>
                        <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 text-end">
                            <button type="button" class="btn btn-primary radius-16 py-2 px-5" @click="nextStep">
                                التالى
                                <i class="fas fa-arrow-left ps-2"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="currentStep === 11" class="last-step">
                <div class="card-title">
                    <p class="mb-2 dark-text fw-bold h5">إختر نوع التشطيب</p>
                </div>
                <div class="card-body mt-4 px-10 py-10">
                    <div class="row">
                        <div class="col-md-8 col-sm-12 col-lg-8 col-xs-12 pe-4">
                            <div class="row btn-group mt-2 pe-4" role="group" aria-label="Basic checkbox group">
                                <div v-for="type in types" :key="type.id" class="col-md-4 col-lg-4 col-sm-6 col-xs-12">
                                    <button type="button" class="btn btn-outline-secondary px-2 py-4 full-width mb-4"
                                        :class="{ 'active': activeType === type.id }" @click="toggleType(type.id)">
                                        <span>{{ type.label }}</span>
                                        <h4 class="mt-3">{{ type.subLabel }}</h4>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <!-- Selected Total Display -->
                        <div class="col-md-4 col-sm-12 col-lg-4 col-xs-12">
                            <div v-for="item in selectedTotals" :key="item.id"
                                class="card px-3 py-4 radius-16 text-center total mt-2">
                                <h6 class="dark-text mb-1 fw-bold">{{ item.label }}</h6>
                                <h5 class="blue-text mb-1">{{ item.title }}</h5>
                                <h2 class="orange-text fw-bold">{{ totalCost.toFixed(2) }}</h2>
                                <p class="dark-text fw-bold">ريال سعودي</p>
                                <p class="grey-text mb-2">التكاليف الواردة ادناه هي تكاليف تقريبيه للاسترشاد فقط وقد
                                    تختلف من مقاول لاخر ومن مدينه لاخري</p>
                            </div>
                        </div>
                    </div>

                    <!-- Collapse Section for Price Details -->
                    <p class="text-start">
                        <a href="#" class="blue-text fw-bold" @click.prevent="isCollapsed = !isCollapsed">
                            <i class="fas" :class="['pe-1', isCollapsed ? 'fa-chevron-down' : 'fa-chevron-up']"></i>
                            {{ isCollapsed ? 'اظهار تفاصيل الأسعار ومواصفات التشطيب' : 'إخفاء تفاصيل الأسعار ومواصفات التشطيب' }}
                        </a>
                    </p>

                    <div :class="['collapse', { show: !isCollapsed }]" id="collapseExample">
                        <div class="card-content">
                            <div class="card card-details card-body radius-16 mb-4">
                                <div class="card-title blue-text fw-bold mb-3">
                                    تفاصيل الأسعار
                                </div>
                                <div class="card-text text-start">
                                    <ul class="row">
                                        <li v-for="(detail, index) in currentDetails" :key="index"
                                            class="col-md-6 col-sm-6 col-xs-12">
                                            <p class="dark-text">
                                                {{ detail.label }}: <span>{{ detail.value }}</span>
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="card card-details card-body radius-16">
                                <div class="card-title blue-text fw-bold mb-5">
                                    {{ additionalDetail.title }}
                                </div>
                                <div class="card-text text-start">
                                    <p v-html="additionalDetail.description" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer bg-transparent no-border mt-5">
                    <div class="row">
                        <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 text-start">
                            <button v-if="currentStep > 1" type="button" class="btn btn-gray radius-16 py-2 px-5"
                                @click="prevStep">
                                <i class="fas fa-arrow-right pe-2"></i>
                                السابق
                            </button>
                        </div>
                        <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12">
                            <div class="progress mt-3">
                                <div class="progress-bar" :style="{ width: '100%' }" role="progressbar"
                                    aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <p class="text-center fw-bold mt-3 mb-0"><span class="blue-text">10/</span>10</p>
                        </div>
                        <div class="col-md-3 col-lg-3 col-sm-12 col-xs-12 text-end">
                            <button class="btn btn-primary radius-16 py-2 px-5">
                                <a href="/" style="color:#FFF;">
                                إنهاء
                                <i class="fas fa-arrow-left ps-2"></i>
                                </a>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {
            isCollapsed: true, // Track whether the details are shown or hidden
            currentStep: 1,
            selectedVilla: null,
            length: 10.00,
            tankLength: 3.00,
            tankWidth: 2.00,
            tankHeight: 3.00,
            planLength: 3.00,
            planWidth: 2.00,
            planHeight: 3.00,
            width: 10.00,
            groundArea: 100.00,
            turnArea: 100.00,
            cellar: 0.00, // Initialize with a default value
            upperAreaFloor: 50.00,
            defaultUpperArea: 50.00,
            minArea: 10.00,  // minimum allowed area
            activeButtons: [],
            activeType: '',
            selectedTotals: [],
            totalCost: 0,
            streetValues : [],
            details: {
                1: [
                    { label: 'تكاليف الطابق الأرضي', value: '80.000 ريال' },
                    { label: 'تكاليف الطابق الأول', value: '70.000 ريال' },
                    { label: 'تكاليف  السور', value: '119.000 ريال' },
                    { label: 'تكاليف  الفناء الخارجى', value: '119.000 ريال' },
                    { label: 'تكاليف  السطح', value: '119.000 ريال' },
                    { label: 'تكاليف  خزان المياة', value: '119.000 ريال' },
                    { label: 'تكاليف  بيارة الصرف', value: '119.000 ريال' }
                ],
                2: [
                    { label: 'تكاليف الطابق الأرضي', value: '50.000 ريال' },
                    { label: 'تكاليف الطابق الأول', value: '30.000 ريال' },
                    { label: 'تكاليف  السور', value: '50.000 ريال' },
                    { label: 'تكاليف  الفناء الخارجى', value: '119.000 ريال' },
                    { label: 'تكاليف  السطح', value: '119.000 ريال' },
                    { label: 'تكاليف  خزان المياة', value: '119.000 ريال' },
                    { label: 'تكاليف  بيارة الصرف', value: '119.000 ريال' }
                ],
                3: [
                    { label: 'تكاليف الطابق الأرضي', value: '100.000 ريال' },
                    { label: 'تكاليف الطابق الأول', value: '70.000 ريال' },
                    { label: 'تكاليف  السور', value: '29.000 ريال' },
                    { label: 'تكاليف  الفناء الخارجى', value: '119.000 ريال' },
                    { label: 'تكاليف  السطح', value: '119.000 ريال' },
                    { label: 'تكاليف  خزان المياة', value: '119.000 ريال' },
                    { label: 'تكاليف  بيارة الصرف', value: '119.000 ريال' }
                ],
                4: [
                    { label: 'تكاليف الطابق الأرضي', value: '100.000 ريال' },
                    { label: 'تكاليف الطابق الأول', value: '70.000 ريال' },
                    { label: 'تكاليف  السور', value: '29.000 ريال' },
                    { label: 'تكاليف  الفناء الخارجى', value: '119.000 ريال' },
                    { label: 'تكاليف  السطح', value: '119.000 ريال' },
                    { label: 'تكاليف  خزان المياة', value: '119.000 ريال' },
                    { label: 'تكاليف  بيارة الصرف', value: '119.000 ريال' }
                ], 5: [
                    { label: 'تكاليف الطابق الأرضي', value: '100.000 ريال' },
                    { label: 'تكاليف الطابق الأول', value: '70.000 ريال' },
                    { label: 'تكاليف  السور', value: '29.000 ريال' },
                    { label: 'تكاليف  الفناء الخارجى', value: '119.000 ريال' },
                    { label: 'تكاليف  السطح', value: '119.000 ريال' },
                    { label: 'تكاليف  خزان المياة', value: '119.000 ريال' },
                    { label: 'تكاليف  بيارة الصرف', value: '119.000 ريال' }
                ], 6: [
                    { label: 'تكاليف الطابق الأرضي', value: '100.000 ريال' },
                    { label: 'تكاليف الطابق الأول', value: '70.000 ريال' },
                    { label: 'تكاليف  السور', value: '29.000 ريال' },
                    { label: 'تكاليف  الفناء الخارجى', value: '119.000 ريال' },
                    { label: 'تكاليف  السطح', value: '119.000 ريال' },
                    { label: 'تكاليف  خزان المياة', value: '119.000 ريال' },
                    { label: 'تكاليف  بيارة الصرف', value: '119.000 ريال' }
                ]
            },
            additionalDetails: {
                1: { title: 'التشطيب العادي', description: 'وصف التشطيب العادي...' },
                2: { title: 'التشطيب المتوسط', description: 'وصف التشطيب المتوسط...' },
                3: { title: 'التشطيب الفاخر', description: 'وصف التشطيب الفاخر...' },
                4: { title: 'التشطيب الفاخر', description: 'وصف التشطيب الفاخر...' },
                5: { title: 'التشطيب الفاخر', description: 'وصف التشطيب الفاخر...' },
                6: { title: 'التشطيب الفاخر', description: 'وصف التشطيب الفاخر...' },
            },
            currentDetails: [],
            additionalDetail: {},
            types: [
                { id: '1', label: 'بناء', subLabel: 'عظم' },
                { id: '2', label: 'تشطيب', subLabel: 'تجارى' },
                { id: '3', label: 'تشطيب', subLabel: 'عادى' },
                { id: '4', label: 'تشطيب', subLabel: 'لوكس' },
                { id: '5', label: 'تشطيب', subLabel: 'سوبر لوكس' },
                { id: '6', label: 'تشطيب', subLabel: 'هاى كلاس' }
            ],
            totals: [
                { id: '1', label: 'تكاليف', title: 'البناء العظم', total: '165,640' },
                { id: '2', label: 'تكاليف', title: ' التشطيب التجارى', total: '334,640' },
                { id: '3', label: 'تكاليف', title: ' التشطيب العادى', total: '365,950' },
                { id: '4', label: 'تكاليف', title: ' التشطيب اللوكس', total: '498,950' },
                { id: '5', label: 'تكاليف', title: 'التشطيب سوبر لوكس', total: '165,640' },
                { id: '6', label: 'تكاليف', title: 'التشطيب هاى كلاس', total: '165,640' }
            ],

            showAlertDialog: false, // State for controlling the alert dialog visibility
            showAlertDialog2: false, // State for controlling the alert dialog visibility
            showAlertDialog3: false, // State for controlling the alert dialog visibility
            showAlertDialogTankLength: false, // New state for tank length alert
            showAlertDialogTankWidth: false,
            showAlertDialogPlanLength: false, // New state for tank length alert
            showAlertDialogPlanWidth: false,
            showAlertDialog12: false,
            frontageValues: {
                '1': 10.00,
                '2': 10.00,
                '3': 10.00,
                '4': 10.00
            }
        };
    },
    computed: {
        area() {
            return this.length * this.width;
        },
        tankArea() {
            return this.tankLength * this.tankWidth * this.tankHeight;
        },
        planArea() {
            return this.planLength * this.planWidth * this.planHeight;
        },

        formattedArea: {
            get() {
                return this.cellar.toFixed(2);
            },
            set(value) {
                const parsedValue = parseFloat(value);
                if (!isNaN(parsedValue)) {
                    // Ensure cellar does not go below the area
                    this.cellar = Math.max(parsedValue, this.area);
                    this.updateCellar({ target: { value: this.cellar } });
                }
            }
        },
        formattedLength: {
            get() {
                return this.length.toFixed(2);
            },
            set(value) {
                this.updateLength({ target: { value } });
            }
        },
        formattedWidth: {
            get() {
                return this.width.toFixed(2);
            },
            set(value) {
                this.updateWidth({ target: { value } });
            }
        },
        formattedTankLength: {
            get() {
                return this.tankLength.toFixed(2);
            },
            set(value) {
                this.updateTankLength({ target: { value } });
            }
        },
        formattedTankWidth: {
            get() {
                return this.tankWidth.toFixed(2);
            },
            set(value) {
                this.updateTankWidth({ target: { value } });
            }
        },
        formattedPlanLength: {
            get() {
                return this.planLength.toFixed(2);
            },
            set(value) {
                this.updatePlanLength({ target: { value } });
            }
        },
        formattedPlanWidth: {
            get() {
                return this.planWidth.toFixed(2);
            },
            set(value) {
                this.updatePlanWidth({ target: { value } });
            }
        },
        formattedGroundArea: {
            get() {
                return this.groundArea.toFixed(2);
            },
            set(value) {
                this.updateGroundArea(value);
            }
        },
        formattedTurnArea: {
            get() {
                return this.turnArea.toFixed(2);
            },
            set(value) {
                this.updateTurnArea(value);
            }
        },
        formattedUpperAreaFloor() {
            return this.upperAreaFloor.toFixed(2);
        },
        frontageDescription() {
            if (this.activeButtons.length === 0) {
                return 'لم يتم اختيار أي جهة';
            }
            if (this.activeButtons.length === 1) {
                return 'الأرض تطل على شارع واحد';
            }
            if (this.activeButtons.length === 2) {
                return 'الأرض تطل على شارعين';
            }
            if (this.activeButtons.length === 3) {
                return 'الأرض تطل على ثلاث شوارع';
            }
            return 'الأرض تطل على أربع شوارع';
        }
    },
    watch: {
        currentStep(newVal) {
            // Save the current step to localStorage whenever it changes
            localStorage.setItem('currentStep', newVal);
        },
        length(newVal) {
            // Update frontage values when length changes
            this.updateFrontageValues();
            // this.maxArea = this.area;
        },
        width(newVal) {
            // Update frontage values when width changes
            this.updateFrontageValues();
            // this.maxArea = this.area;
        },
        area(newVal) {
            this.cellar = newVal; // Update cellar when area changes
        }
    },
    mounted() {
        // const savedStep = localStorage.getItem('currentStep');
        // if (savedStep) {
        //     this.currentStep = parseInt(savedStep, 10); // Restore the saved step
        // }

        this.cellar = this.area;
    },
    methods: {
        toggleButton(buttonId) {
            if (this.activeButtons.includes(buttonId)) {
                this.activeButtons = this.activeButtons.filter(id => id !== buttonId);
            } else {
                this.activeButtons.push(buttonId);
                this.streetValues.push(this.frontageValues[buttonId])
            }
        },

        toggleType(id) {
            this.activeType = id; // Set the active button
            this.selectedTotals = this.totals.filter(item => item.id === this.activeType); // Get the current item's content
            axios.post('/building_calculation', {
                finish_id: id,
                widthValue: this.width,
                lengthValue: this.length,
                areaValue: this.area,
                villaType: this.selectedVilla,
                groundValue: this.formattedGroundArea,
                secondFloorValue: this.formattedTurnArea,
                roofValue: this.formattedUpperAreaFloor,
                basementValue: this.formattedArea,
                tankValue: this.tankArea,
                planValue: this.planArea,
                streetValues: this.streetValues,

                // Data to send in the request
                cellarValue: this.cellar, // Example of sending the cellar value
            })
                .then(response => {
                    // Handle success
                    console.log("Data submitted successfully:", response.data);
                    let data = response.data;
                    this.totalCost = response.data.totalCost;
                    this.currentDetails = [
                        { label: 'تكاليف الطابق الأرضي', value: data.groundFloorPrice + 'ريال' },
                        { label: 'تكاليف الطابق الأول', value: data.secondFloorPrice + 'ريال' },
                        { label: 'تكاليف الملحق العلوي', value: data.roofPrice + 'ريال' },
                        { label: 'تكاليف سور السطح', value: data.roofWallPrice + 'ريال' },
                        { label: 'تكاليف القبو', value: data.basementPrice + 'ريال' },
                        { label: 'تكاليف  الفناء الخارجى', value: data.outdoorPatioPrice + 'ريال' },
                        { label: 'تكاليف  السور', value: data.baseWallPrice + 'ريال' },
                        { label: 'تكاليف  السور المطل علي الشارع', value: data.streetWallPrice + 'ريال' },
                        { label: 'تكاليف  خزان المياة', value: data.tankPrice + 'ريال' },
                        { label: 'تكاليف  بيارة الصرف', value: data.drainPrice + 'ريال' }
                    ];
                    this.additionalDetail = { title: data.finishData.current_description.name, description: data.finishData.current_description.description };
                    console.log(this.additionalDetail);
                    //this.currentStep += 1; // Move to the next step after successful request
                })
                .catch(error => {
                    // Handle error
                    console.error("There was an error submitting the data:", error);
                    // Optionally, you could show an alert or message to the user here
                });
        },
        nextStep() {
            if (this.currentStep === 2 && this.activeButtons.length === 0) {
                this.showAlertDialog = true; // Show alert if no button is selected in step 2
                return; // Stop the navigation
            }

            // Always increment currentStep first
            if (this.currentStep <= 10) {
                this.currentStep += 1; // Move to the next step right away
            }

            // If on step 10, send the pre-final request and still move to step 11
            if (this.currentStep === 10) {
                // axios.post('/pre_final_calculation', {
                //     widthValue: this.width,
                //     lengthValue: this.length,
                //     areaValue: this.area,
                //     villaType: this.selectedVilla,
                //     groundValue: this.formattedGroundArea,
                //     secondFloorValue: this.formattedTurnArea,
                //     roofValue: this.formattedUpperAreaFloor,
                //     basementValue: this.formattedArea,
                //     tankValue: this.tankArea,
                //     planValue: this.planArea,
                //     streetValues: this.activeButtons,
                // })
                //     .then(response => {
                //         console.log("Pre-final calculation submitted successfully:", response.data);
                //         // Save the response data if needed before moving to the final step
                //         this.preFinalDetails = response.data;
                //     })
                //     .catch(error => {
                //         console.error("Error in pre-final calculation submission:", error);
                //     });
            }

            // Submit final calculation on step 11
            if (this.currentStep === 11) {
                axios.post('/building_calculation', {
                    widthValue: this.width,
                    lengthValue: this.length,
                    areaValue: this.area,
                    villaType: this.selectedVilla,
                    groundValue: this.formattedGroundArea,
                    secondFloorValue: this.formattedTurnArea,
                    roofValue: this.formattedUpperAreaFloor,
                    basementValue: this.formattedArea,
                    tankValue: this.tankArea,
                    planValue: this.planArea,
                    streetValues: this.streetValues,
                })
                    .then(response => {
                        console.log("Data submitted successfully:", response.data);
                        let data = response.data;
                        this.totalCost = data.totalCost;
                        this.currentDetails = [
                            { label: 'تكاليف الطابق الأرضي', value: data.groundFloorPrice + 'ريال' },
                            { label: 'تكاليف الطابق الأول', value: data.secondFloorPrice + 'ريال' },
                            { label: 'تكاليف الملحق العلوي', value: data.roofPrice + 'ريال' },
                            { label: 'تكاليف سور السطح', value: data.roofWallPrice + 'ريال' },
                            { label: 'تكاليف القبو', value: data.basementPrice + 'ريال' },
                            { label: 'تكاليف الفناء الخارجى', value: data.outdoorPatioPrice + 'ريال' },
                            { label: 'تكاليف السور', value: data.baseWallPrice + 'ريال' },
                            { label: 'تكاليف السور المطل علي الشارع', value: data.streetWallPrice + 'ريال' },
                            { label: 'تكاليف خزان المياة', value: data.tankPrice + 'ريال' },
                            { label: 'تكاليف بيارة الصرف', value: data.drainPrice + 'ريال' }
                        ];
                        this.additionalDetail = {
                            title: data.finishData.current_description.name,
                            description: data.finishData.current_description.description
                        };
                        console.log(this.additionalDetail);
                    })
                    .catch(error => {
                        console.error("There was an error submitting the data:", error);
                    });
            }
        },

        prevStep() {
            if (this.currentStep > 1) {
                this.currentStep -= 1;
            }
            if (this.currentStep == 'one-turn') {
                this.currentStep = 4;
            }
        },
        selectVillaType(type) {
            this.selectedVilla = type;
            this.currentStep = 4;
        },
        upperArea(value) {
            if (value === 1) {
                this.upperAreaFloor = 50.00;
                this.currentStep = 6;
                // Set the current step to 4

            } else if (value === 0) {
                this.upperAreaFloor = 0; // Set to 0
                this.currentStep = 7;
            }
        },
        cellarStep() {
            this.currentStep = 7;
        },

        goToNextStep() {
            // إذا كان المستخدم قد اختار فيلا دورين، الانتقال إلى الخطوة 5
            if (this.selectedVilla === 'two') {
                this.currentStep = 'one-turn';
            } else {
                this.currentStep = 5;
            }
        },
        twofloorvilla() {
            // Add logic for two-floor villa if needed
            this.currentStep = 4;
        },
        goToStep5() {
            this.currentStep = 5;
        },
        cellarArea(value) {
            if (value === 1) {
                this.cellar = this.area;
                this.currentStep = 8;

            } else if (value === 0) {
                this.cellar = 0;
                this.currentStep = 9;
            }

        },
        GoToStep9() {
            this.currentStep = 9;
        },
        closeAlert() {
            this.showAlertDialog = false; // Close the alert dialog
        },
        updateFrontageValues() {
            // Dynamically update values based on button selections
            this.frontageValues['1'] = this.length;
            this.frontageValues['2'] = this.width;
            this.frontageValues['3'] = this.length;
            this.frontageValues['4'] = this.width;
        },
        increment(dimension) {
            if (dimension === 'length') {
                this.length = parseFloat((this.length + 0.50).toFixed(2));
            } else if (dimension === 'width') {
                this.width = parseFloat((this.width + 0.50).toFixed(2));
            }
            else if (dimension === 'groundArea') {
                const maxGroundArea = this.area * 0.70; // Calculate 70% of total area

                if (this.groundArea < maxGroundArea) {
                    this.groundArea = Math.max(100.00, parseFloat((this.groundArea + 0.50).toFixed(2)));
                } else {
                    this.showAlertDialog2 = true; // Show alert if no button is selected in step 4
                }
            }
            else if (dimension === 'turnArea') {
                const maxTurnArea = this.area * 0.65; // Calculate 70% of total area

                if (this.turnArea < maxTurnArea) {
                    this.turnArea = Math.max(100.00, parseFloat((this.turnArea + 0.50).toFixed(2)));
                } else {
                    this.showAlertDialog12 = true; // Show alert if no button is selected in step 4
                }
            }
            else if (dimension === 'upperAreaFloor') {
                const maxUpperArea = this.groundArea * 0.50;
                if (this.upperAreaFloor + 0.50 <= maxUpperArea) {
                    this.upperAreaFloor = parseFloat((this.upperAreaFloor + 0.50).toFixed(2));
                    this.showAlertDialog3 = false;
                } else {
                    this.showAlertDialog3 = true;
                }
            }
            else if (dimension === 'tankLength') {
                if (this.tankLength < 15) {
                    this.tankLength = parseFloat((this.tankLength + 0.50).toFixed(2));
                }
            } else if (dimension === 'tankWidth') {
                if (this.tankWidth < 8) {
                    this.tankWidth = parseFloat((this.tankWidth + 0.50).toFixed(2));
                }
            }
            else if (dimension === 'cellar') {
                if (this.cellar < this.area) {
                    // Increment cellar by 0.50
                    this.cellar = parseFloat((this.cellar + 0.50).toFixed(2));
                }
            }
            else if (dimension === 'planLength') {
                if (this.planLength < 15) {
                    this.planLength = parseFloat((this.planLength + 0.50).toFixed(2));
                }
            } else if (dimension === 'planWidth') {
                if (this.planWidth < 8) {
                    this.planWidth = parseFloat((this.planWidth + 0.50).toFixed(2));
                }
            }
        },
        decrement(dimension) {
            if (dimension === 'length') {
                this.length = Math.max(10.00, parseFloat((this.length - 0.50).toFixed(2)));
            } else if (dimension === 'width') {
                this.width = Math.max(10.00, parseFloat((this.width - 0.50).toFixed(2)));
            }
            else if (dimension === 'groundArea') {
                const maxGroundArea = this.area * 0.70; // Calculate 70% of total area

                if (this.groundArea > maxGroundArea) {
                    // Decrease groundArea if it's greater than maxGroundArea
                    this.groundArea = Math.max(100.00, parseFloat((this.groundArea - 0.50).toFixed(2)));
                } else {
                    this.groundArea = Math.max(100.00, parseFloat((this.groundArea - 0.50).toFixed(2)));
                    this.showAlertDialog2 = false;
                }
            }
            else if (dimension === 'turnArea') {
                const maxTurnArea = this.area * 0.70; // Calculate 70% of total area

                if (this.turnArea > maxTurnArea) {
                    // Decrease turnArea if it's greater than maxturnArea
                    this.turnArea = Math.max(100.00, parseFloat((this.turnArea - 0.50).toFixed(2)));
                } else {
                    this.turnArea = Math.max(100.00, parseFloat((this.turnArea - 0.50).toFixed(2)));
                    this.showAlertDialog12 = false;
                }
            }
            else if (dimension === 'upperAreaFloor') {
                if (this.upperAreaFloor - 0.50 >= 50.00) {
                    this.upperAreaFloor = parseFloat((this.upperAreaFloor - 0.50).toFixed(2));
                    this.showAlertDialog3 = false;
                }
            }
            else if (dimension === 'tankLength') {
                this.tankLength = Math.max(3.00, parseFloat((this.tankLength - 0.50).toFixed(2)));
            } else if (dimension === 'tankWidth') {
                this.tankWidth = Math.max(2.00, parseFloat((this.tankWidth - 0.50).toFixed(2)));
            }
            else if (dimension === 'planLength') {
                this.planLength = Math.max(3.00, parseFloat((this.planLength - 0.50).toFixed(2)));
            } else if (dimension === 'planWidth') {
                this.planWidth = Math.max(2.00, parseFloat((this.planWidth - 0.50).toFixed(2)));
            } else if (dimension === 'cellar') {
                // Only decrement if cellar is greater than the minimum allowed
                if (this.cellar > 10.00) {
                    this.cellar = parseFloat((this.cellar - 0.50).toFixed(2));
                }
            }
        },

        updateLength(event) {
            const value = parseFloat(event.target.value);
            if (!isNaN(value)) {
                this.length = Math.max(Math.round(value * 100) / 100, 10.00);
            }
        },

        updateCellar(event) {
            const value = parseFloat(event.target.value);
            if (!isNaN(value)) {
                // Ensure cellar does not go below the area
                if (value < this.area) {
                    this.cellar = parseFloat(value.toFixed(2));
                } else {
                    this.cellar = this.area;
                }
            } else {
                this.cellar = this.area;
            }
        },
        updateTankLength(event) {
            const value = parseFloat(event.target.value);
            if (!isNaN(value)) {
                if (value > 15) {
                    this.showAlertDialogTankLength = true; // Show alert if value is greater than 15
                } else {
                    this.showAlertDialogTankLength = false; // Hide alert if value is valid
                    this.tankLength = Math.max(Math.round(value * 100) / 100, 3.00);
                }
            }
        },
        updatePlanLength(event) {
            const value = parseFloat(event.target.value);
            if (!isNaN(value)) {
                if (value > 15) {
                    this.showAlertDialogPlanLength = true; // Show alert if value is greater than 15
                } else {
                    this.showAlertDialogPlanLength = false; // Hide alert if value is valid
                    this.planLength = Math.max(Math.round(value * 100) / 100, 3.00);
                }
            }
        },
        updateWidth(event) {
            const value = parseFloat(event.target.value);
            if (!isNaN(value)) {
                this.width = Math.max(Math.round(value * 100) / 100, 10.00);
            }
        },
        updateTankWidth(event) {
            const value = parseFloat(event.target.value);
            if (!isNaN(value)) {
                if (value > 8) {
                    this.showAlertDialogTankWidth = true; // Show alert if value is greater than 15
                }
                else {
                    this.showAlertDialogTankWidth = false; // Hide alert if value is valid
                    this.tankWidth = Math.max(Math.round(value * 100) / 100, 2.00);
                }
            }
        },
        updatePlanWidth(event) {
            const value = parseFloat(event.target.value);
            if (!isNaN(value)) {
                if (value > 8) {
                    this.showAlertDialogPlanWidth = true; // Show alert if value is greater than 15
                }
                else {
                    this.showAlertDialogPlanWidth = false; // Hide alert if value is valid
                    this.planWidth = Math.max(Math.round(value * 100) / 100, 2.00);
                }
            }
        },
        updateGroundArea(value) {
            const parsedValue = parseFloat(value);
            if (!isNaN(parsedValue)) {
                const maxGroundArea = this.area * 0.70;
                if (parsedValue <= maxGroundArea && parsedValue >= 100.00) {
                    this.groundArea = parseFloat(parsedValue.toFixed(2));
                    this.showAlertDialog2 = false;
                } else if (parsedValue > maxGroundArea) {
                    this.showAlertDialog2 = true;
                } else if (parsedValue < 100.00) {
                    this.groundArea = 100.00;
                    this.showAlertDialog12 = false;
                }
            }
        },
        updateTurnArea(value) {
            const parsedValue = parseFloat(value);
            if (!isNaN(parsedValue)) {
                const maxTurnArea = this.area * 0.70;
                if (parsedValue <= maxTurnArea && parsedValue >= 100.00) {
                    this.turnArea = parseFloat(parsedValue.toFixed(2));
                    this.showAlertDialog12 = false;
                } else if (parsedValue > maxTurnArea) {
                    this.showAlertDialog12 = true;
                } else if (parsedValue < 100.00) {
                    this.turnArea = 100.00;
                    this.showAlertDialog12 = false;
                }
            }
        },
        updateUpperAreaFloor(event) {
            const value = event.target.value; // Get the value from the input event
            const parsedValue = parseFloat(value); // Parse the value to a float
            const maxUpperArea = this.groundArea * 0.50; // Calculate 50% of the ground area

            // Check if the parsed value is a valid number
            if (!isNaN(parsedValue)) {
                if (parsedValue > maxUpperArea) {
                    this.showAlertDialog3 = true; // Show alert if the value exceeds 50% of the ground area
                    this.upperAreaFloor = parseFloat(maxUpperArea.toFixed(2)); // Set value to max allowed
                } else if (parsedValue >= 50.00) {
                    this.upperAreaFloor = parseFloat(parsedValue.toFixed(2)); // Update value if within the valid range
                    this.showAlertDialog3 = false; // Hide alert
                } else {
                    // this.upperAreaFloor = 50.00; // Set value to minimum allowed if it's below 50
                    this.showAlertDialog3 = true; // Hide alert
                }
            } else {
                this.showAlertDialog3 = false; // Hide alert if the value is invalid
            }
        },
    },
    created() {
        if (this.types.length > 0) {
            this.activeType = this.types[0].id; // Set the first button as active
            this.currentDetails = this.details[this.activeType] || []; // Set initial details
            this.additionalDetail = this.additionalDetails[this.activeType] || {}; // Set initial additional details
            this.selectedTotals = this.totals.filter(item => item.id === this.activeType); // Update selected totals
        }
    },
};
</script>
<style scoped lang="scss">
/* Add any additional styling here */
.btn-outline-secondary.active {
    border: 1px solid #FC7100;
    background-color: #FFF !important;
}

.size-8 {
    font-size: 14px !important;
}

.closeAlert {
    position: absolute;
    top: 10px;
    left: 8px;
}

.last-step .card-body {
    padding: 30px;
}
</style>